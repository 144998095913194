<template>
  <div class="container mt-5">
    <div class="card shadow-sm">
      <div class="card-header bg-primary text-white">
        <h3 class="mb-0">Select Date Range to Create Reports</h3>
      </div>
      <div class="card-body">
        <form @submit.prevent="submitForm">
          <div class="row mb-3">
            <div class="col-md-12">
              <label for="redmineApiKey" class="form-label">Redmine API Key:</label>
              <input
                type="text"
                v-model="redmineApiKey"
                class="form-control"
                id="redmineApiKey"
                placeholder="Enter your Redmine API Key"
                required
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <label for="fromDate" class="form-label">From Date:</label>
              <div class="input-group">
                <span class="input-group-text" id="inputGroupPrepend">
                  <i class="bi bi-calendar-event"></i>
                </span>
                <input type="date" v-model="fromDate" class="form-control" id="fromDate" required />
              </div>
            </div>
            <div class="col-md-6">
              <label for="toDate" class="form-label">To Date:</label>
              <div class="input-group">
                <span class="input-group-text" id="inputGroupPrepend">
                  <i class="bi bi-calendar-event"></i>
                </span>
                <input type="date" v-model="toDate" class="form-control" id="toDate" required />
              </div>
            </div>
          </div>
          <div class="d-grid gap-2">
            <button type="submit" class="btn btn-success btn-block" :disabled="isLoading">
              <i class="bi bi-cloud-download"></i>
              <span v-if="isLoading">Loading...</span>
              <span v-else>Create</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
      {{ errorMessage }}
    </div>

    <div v-if="files.length" class="mt-5">
      <div class="card">
        <div class="card-header bg-secondary text-white">
          <h4 class="mb-0">Reports</h4>
        </div>
        <div class="card-body">
          <ul class="list-group">
            <li v-for="file in files" :key="file.id" class="list-group-item d-flex justify-content-between align-items-center">
              <span>{{ file.name }}</span>
              <a :href="file.url" download class="btn btn-outline-primary">
                <i class="bi bi-download"></i> Download
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getConfig } from '../services/configService.js';

export default {
  data() {
    return {
      redmineApiKey: '', // Field for API key
      fromDate: '',
      toDate: '',
      files: [], // List of files with modified URLs
      isLoading: false, // Loading state indicator
      errorMessage: '' // Error message for popup
    };
  },
  created() {
    getConfig().then(config => {
      if (config) {
        this.APIURL = config.APIURL; // Assuming APIURL is the URL of your API
      }
    });
  },
  methods: {
    async submitForm() {
      if (!this.redmineApiKey.trim()) {
        alert('Please enter your Redmine API Key.');
        return;
      }

      this.isLoading = true; // Enable loading indicator
      this.files = []; // Clear files list before loading new data
      this.errorMessage = ''; // Clear previous error message

      try {
        const response = await axios.post(`${this.APIURL}api/parse`, {
          redmineApikey: this.redmineApiKey, // Pass API key to the server
          fromDate: this.fromDate,
          toDate: this.toDate
        });
        // Update file URLs to use HTTPS
        this.files = response.data.map(file => ({
          ...file,
          url: this.replaceHttpWithHttps(file.url)
        }));
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errorMessage = error.response.data || 'Bad Request: Invalid input.';
        } else {
          this.errorMessage = 'An unexpected error occurred.';
        }
        console.error('Error fetching files:', error);
      } finally {
        this.isLoading = false; // Disable loading indicator
      }
    },
    replaceHttpWithHttps(url) {
      return url.startsWith('http://') ? url.replace('http://', 'https://') : url;
    }
  }
};
</script>


<style scoped>
.container {
  max-width: 800px;
  margin: auto;
}

.card {
  border-radius: 10px;
}

.btn {
  font-size: 1.2rem;
}

.bi {
  margin-right: 8px;
}

.alert {
  font-size: 1rem;
}
</style>
