// configService.js
import axios from 'axios';

export const getConfig = async () => {
  const configFile = process.env.VUE_APP_SETTINGS_FILE;
  try {
    const response = await axios.get(`${configFile}`);
    return response.data;  // Возвращает объект конфигурации
  } catch (error) {
    console.error('Error loading the config file:', error);
    return null;
  }
};
