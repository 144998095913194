<template>
  <div id="app">
    <header class="bg-primary text-white p-3 text-center">
      <h1>PBAS Redmine Reports</h1>
    </header>
    
    <main>
      <DatePicker />
    </main>

    <footer class="bg-light text-center p-3 mt-auto">
      <p>&copy; DotByDot 2024 Illia Koskin</p>
    </footer>
  </div>
</template>

<script>
import DatePicker from './components/DatePicker.vue';

export default {
  name: 'App',
  components: {
    DatePicker
  }
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  padding: 20px;
}
</style>
